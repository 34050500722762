<template>
  <div>
    <!-- nps -->
    <BrandSurveyPopup
      :show.sync="staticModal.show"
      v-if="this.evulationSurveyType === 6 || this.evulationSurveyType === 7"
      @update:show="closeModal"
    >
      <div class="static-content-modal">
        <div class="nps-start-screen" v-if="step === 0 && surveyModel.responseType === 1">
          <img src="@/assets/logos/pmaktif.png" alt="pmaktif" class="pmaktif-logo" />
          <div class="info-text" v-if="surveyDescription" v-html="surveyDescription"></div>
          <div class="content-text" v-else>
            <p>
              Değerli bayimiz sizlere daha iyi hizmet verebilmek adına hazırladığımız
              <span class="bold-text"
                >{{ questionCount ? questionCount : '' }} soruluk anketimize</span
              >
              katılmanızdan mutluluk duyacağız.
            </p>
            <p>
              Philip Morris ile olan iş ortaklığınız, Ön Sipariş sistemi ve PMAktif başlıklarında
              çıkacak sorularda, her soruyu kendi konusu dahilinde değerlendirip varsa yorumlarınızı
              iletmenizi rica ederiz.
            </p>
          </div>
          <BrandButton class="start-button" @click.prevent="startSurvey">
            <VueText color="white-100" sizeLevel="10" weightLevel="4">BAŞLA</VueText>
          </BrandButton>
        </div>
        <div class="question-screen" v-else>
          <div class="modal-content">
            <div
              class="step-bar"
              v-if="
                surveyModel.responseType !== 4 &&
                  surveyModel.responseType !== 1 &&
                  questionCount !== 1
              "
            >
              <ul class="progressbar">
                <li
                  v-for="(item, index) in questionCount"
                  :class="index + 1 <= step ? 'active' : ''"
                  :key="item"
                ></li>
              </ul>
            </div>
            <div class="content-text" v-if="surveyModel.responseType === 2">
              <div v-if="media.enabled" class="media-container">
                <BrandMediaContentBox
                  v-if="surveyModel.mainQuestion.image"
                  :mediaSrc="surveyModel.mainQuestion.image"
                  allowfullscreen
                  :aspectRatios="media.ratio"
                  :data="surveyModel.mainQuestion"
                >
                </BrandMediaContentBox>
                <BrandVimeoPlayer
                  ref="vimeoPlayer"
                  :id="surveyModel.mainQuestion.embedVideo"
                  v-if="surveyModel.mainQuestion.embedVideo"
                  :data="surveyModel.mainQuestion"
                ></BrandVimeoPlayer>
              </div>
              <div class="question-text">
                <p>
                  {{ surveyModel.mainQuestion.question }}
                </p>
              </div>
              <ul class="question-vote">
                <li
                  v-for="vote in filteredVote"
                  :key="vote.value"
                  :class="[{ active: isVoteActive(vote.value) }, 'vote-item']"
                  :data-id="vote.value"
                >
                  <a :class="vote.voteClass" @click="pointVote(vote.value)">{{ vote.value }}</a>
                </li>
                <li class="no-interview" v-if="surveyModel.mainQuestion.noInterviewOption">
                  <a @click="pointInterview()" class="no-interview-item">X</a>
                  <span>Görüşme Yapmadım</span>
                </li>
              </ul>
              <div class="vote-info">
                <img src="@/assets/icons/info.svg" alt="pmaktif" class="vote-info-icon" />
                <div class="vote-info-text">
                  <p>
                    <span
                      >{{ surveyModel.mainQuestion.subQuestions[0].minPoint }}-{{
                        surveyModel.mainQuestion.subQuestions[2].maxPoint
                      }}</span
                    >
                    arasında bir puan veriniz.
                  </p>
                  <p v-if="evulationSurveyType === 6">
                    <span>{{ surveyModel.mainQuestion.subQuestions[0].minPoint }}</span> Kesinlikle
                    tavsiye etmem.
                    <span>{{ surveyModel.mainQuestion.subQuestions[2].maxPoint }}</span> Kesinlikle
                    tavsiye ederim.
                  </p>
                  <p v-if="evulationSurveyType === 7">
                    <span>{{ surveyModel.mainQuestion.subQuestions[0].minPoint }}</span> Hiç memnun
                    kalmadım.
                    <span>{{ surveyModel.mainQuestion.subQuestions[2].maxPoint }}</span> Çok memnun
                    kaldım.
                  </p>
                </div>
              </div>
            </div>
            <div class="content-text" v-if="surveyModel.responseType === 3">
              <div class="question-text">
                <p>
                  {{ surveyModel.question.question }}
                </p>
              </div>
              <!-- çoklu seçenek sorusu -->
              <ul class="questions-answers" v-if="surveyModel.question.isMultiChoise">
                <li v-for="item in surveyModel.question.options" :key="item.optionId">
                  <label class="container"
                    >{{ item.option }}
                    <input
                      type="checkbox"
                      :id="item.optionId"
                      :value="item.optionId"
                      v-model="selectedAnswers"
                    />
                    <span class="checkmark"></span>
                  </label>
                </li>
                <li v-if="surveyModel.question.isOpenText">
                  <label class="container"
                    >Diğer
                    <input type="checkbox" v-model="otherOption" />
                    <span class="checkmark"></span>
                  </label>
                </li>
                <div
                  class="question-text-area"
                  v-if="
                    surveyModel.question.isOpenText &&
                      otherOption &&
                      surveyModel.question.options.length !== 0
                  "
                >
                  <textarea
                    v-model="openText"
                    placeholder="Diğer nedeninizi giriniz..."
                    class="option"
                    rows="3"
                  ></textarea>
                </div>
              </ul>
              <!-- tekli seçenek sorusu -->
              <div
                v-if="
                  !surveyModel.question.isMultiChoise && surveyModel.question.options.length !== 0
                "
              >
                <VueListView dir="column" class="option-list">
                  <VueButton
                    class="option"
                    v-for="op in surveyModel.question.options"
                    :key="op.optionId"
                    :size="sizes.xLarge"
                    :isSingleLine="false"
                    :class="{ 'selected-option': selectedAnswers.includes(op.optionId) }"
                    :contentAlignment="constants.flexAlignment.start"
                    @click="selectOption(op.optionId)"
                    outlined
                    >{{ op.option }}</VueButton
                  >
                </VueListView>
                <div class="other-option" v-if="surveyModel.question.isOpenText">
                  <label class="container"
                    >Diğer
                    <input type="checkbox" v-model="otherOption" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div
                  class="question-text-area"
                  v-if="
                    surveyModel.question.isOpenText &&
                      otherOption &&
                      surveyModel.question.options.length !== 0
                  "
                >
                  <textarea
                    v-model="openText"
                    placeholder="Diğer nedeninizi giriniz..."
                    class="option"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <!-- sadece open text -->
              <div
                class="question-text-area"
                v-if="surveyModel.question.isOpenText && surveyModel.question.options.length === 0"
              >
                <textarea
                  v-model="openText"
                  placeholder="Cevabınızı giriniz..."
                  class="option"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="content-text" v-if="surveyModel.responseType === 4">
              <div class="finally-screen">
                <img src="~@/assets/icons/check_green.svg" alt="check" />
                <p>Anket Tamamlandı</p>
                <span>Katılımınız için teşekkürler!</span>
              </div>
            </div>
          </div>
          <div class="question-button-wrapper">
            <BrandButton
              v-if="surveyModel.responseType === 4"
              class="finally-button"
              @click.prevent="onFinishSurvey"
            >
              <VueText color="white-100" sizeLevel="10" weightLevel="4">TAMAM</VueText>
            </BrandButton>
            <ul class="question-buttons" v-else>
              <li
                v-if="!(surveyModel.responseType === 2 && this.step === 1)"
                style="padding-right: 2px; padding-left: 2px;"
              >
                <BrandButton class="prev-button" outlined @click.prevent="prevButton">
                  <VueText color="grey-50" sizeLevel="10" weightLevel="3">GERİ</VueText>
                </BrandButton>
              </li>
              <li
                v-if="surveyModel.responseType === 2"
                :style="{
                  width: surveyModel.responseType === 2 && this.step === 1 ? '100%' : '50%',
                }"
              >
                <BrandButton
                  class="next-button"
                  @click.prevent="nextButton"
                  :disabled="!point && point !== 0"
                >
                  <VueText color="white-100" sizeLevel="10" weightLevel="3">DEVAM</VueText>
                </BrandButton>
              </li>
              <li v-if="surveyModel.responseType === 3">
                <BrandButton
                  class="next-button"
                  @click.prevent="nextButton()"
                  :disabled="setNextButtonDisable"
                >
                  <VueText color="white-100" sizeLevel="10" weightLevel="3">DEVAM</VueText>
                </BrandButton>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </BrandSurveyPopup>
    <!-- popup survey -->
    <BrandSurveyPopup
      @update:show="closeModal"
      :show.sync="showPopupSurvey"
      v-if="this.evulationSurveyType === 8"
    >
      <div class="static-content-modal popup-survey">
        <div class="nps-start-screen" v-if="step === 0 && surveyModel.responseType === 1">
          <img src="@/assets/logos/pmaktif.png" alt="pmaktif" class="pmaktif-logo" />
          <div class="info-text" v-if="surveyDescription" v-html="surveyDescription"></div>
          <div class="content-text" v-else>
            <p>
              Değerli bayimiz sizlere daha iyi hizmet verebilmek adına hazırladığımız
              <span class="bold-text"
                >{{ questionCount ? questionCount : '' }} soruluk anketimize</span
              >
              katılmanızdan mutluluk duyacağız.
            </p>
            <p>
              Philip Morris ile olan iş ortaklığınız, Ön Sipariş sistemi ve PMAktif başlıklarında
              çıkacak sorularda, her soruyu kendi konusu dahilinde değerlendirip varsa yorumlarınızı
              iletmenizi rica ederiz.
            </p>
          </div>
          <BrandButton class="start-button" @click.prevent="startSurvey">
            <VueText color="white-100" sizeLevel="10" weightLevel="4">BAŞLA</VueText>
          </BrandButton>
        </div>
        <div class="question-screen" v-else>
          <div class="modal-content">
            <div class="content-text" v-if="surveyModel.responseType === 2">
              <div class="question-text">
                <p>
                  {{ surveyModel.mainQuestion.question }}
                </p>
              </div>
              <!-- çoklu seçenek sorusu -->
              <ul class="questions-answers" v-if="surveyModel.questionDto.isMultiChoise">
                <li v-for="item in surveyModel.questionDto.options" :key="item.optionId">
                  <label class="container"
                    >{{ item.option }}
                    <input
                      type="checkbox"
                      :id="item.optionId"
                      :value="item.optionId"
                      v-model="selectedAnswers"
                    />
                    <span class="checkmark"></span>
                  </label>
                </li>
                <li v-if="surveyModel.questionDto.isOpenText">
                  <label class="container"
                    >Diğer
                    <input type="checkbox" v-model="otherOption" />
                    <span class="checkmark"></span>
                  </label>
                </li>
                <div
                  class="question-text-area"
                  v-if="
                    surveyModel.questionDto.isOpenText &&
                      otherOption &&
                      surveyModel.questionDto.options.length !== 0
                  "
                >
                  <textarea
                    v-model="openText"
                    placeholder="Diğer nedeninizi giriniz..."
                    class="option"
                    rows="3"
                  ></textarea>
                </div>
              </ul>
              <!-- tekli seçenek sorusu -->
              <div
                v-if="
                  !surveyModel.questionDto.isMultiChoise &&
                    surveyModel.questionDto.options.length !== 0
                "
              >
                <VueListView dir="column" class="option-list">
                  <VueButton
                    class="option"
                    v-for="op in surveyModel.questionDto.options"
                    :key="op.optionId"
                    :size="sizes.xLarge"
                    :isSingleLine="false"
                    :class="{ 'selected-option': selectedAnswers.includes(op.optionId) }"
                    :contentAlignment="constants.flexAlignment.start"
                    @click="selectOption(op.optionId)"
                    outlined
                    >{{ op.option }}</VueButton
                  >
                </VueListView>
                <div class="other-option" v-if="surveyModel.questionDto.isOpenText">
                  <label class="container"
                    >Diğer
                    <input type="checkbox" v-model="otherOption" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div
                  class="question-text-area"
                  v-if="
                    surveyModel.questionDto.isOpenText &&
                      otherOption &&
                      surveyModel.questionDto.options.length !== 0
                  "
                >
                  <textarea
                    v-model="openText"
                    placeholder="Diğer nedeninizi giriniz..."
                    class="option"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <!-- sadece open text -->
              <div
                class="question-text-area"
                v-if="
                  surveyModel.questionDto.isOpenText && surveyModel.questionDto.options.length === 0
                "
              >
                <textarea
                  v-model="openText"
                  placeholder="Cevabınızı giriniz..."
                  class="option"
                  rows="3"
                ></textarea>
              </div>
              <!-- Time Question Type -->
              <template v-if="surveyModel.questionDto.questionStyle === 3">
                <div v-if="surveyModel.questionDto.dateAnswerType === 1">
                  <datepicker
                    wrapper-class="date-wrapper"
                    input-class="date-input"
                    placeholder="Seçiniz"
                    format="dd/MM/yyyy"
                    v-model="date"
                    inline
                  />
                </div>
                <div v-if="surveyModel.questionDto.dateAnswerType === 2">
                  <vue-timepicker
                    class="time-picker"
                    placeholder="Seçiniz"
                    close-on-complete
                    hide-clear-button
                    hide-dropdown
                    hour-label="Saat"
                    minute-label="Dakika"
                    v-model="hour"
                  ></vue-timepicker>
                </div>
                <div v-if="surveyModel.questionDto.dateAnswerType === 3" class="picker-wrapper">
                  <datepicker
                    wrapper-class="date-wrapper"
                    input-class="date-input"
                    placeholder="Seçiniz"
                    format="dd/MM/yyyy"
                    v-model="date"
                    :language="tr"
                    inline
                  ></datepicker>
                  <vue-timepicker
                    class="time-picker"
                    placeholder="Seçiniz"
                    close-on-complete
                    hide-clear-button
                    hide-dropdown
                    hour-label="Saat"
                    minute-label="Dakika"
                    v-model="hour"
                  ></vue-timepicker>
                </div>
              </template>
            </div>
            <div class="content-text" v-if="surveyModel.responseType === 4">
              <div class="finally-screen">
                <img src="~@/assets/icons/check_green.svg" alt="check" />
                <p>Anket Tamamlandı</p>
                <span>Katılımınız için teşekkürler!</span>
              </div>
            </div>
          </div>
          <div class="question-button-wrapper">
            <BrandButton
              v-if="surveyModel.responseType === 4"
              class="finally-button"
              @click.prevent="onFinishSurvey"
            >
              <VueText color="white-100" sizeLevel="10" weightLevel="4">TAMAM</VueText>
            </BrandButton>
            <ul class="question-buttons" v-else>
              <li
                v-if="!(surveyModel.responseType === 2 && this.step === 1)"
                style="padding-right: 2px; padding-left: 2px;"
              >
                <BrandButton class="prev-button" outlined @click.prevent="prevButton">
                  <VueText color="grey-50" sizeLevel="10" weightLevel="3">GERİ</VueText>
                </BrandButton>
              </li>
              <li
                v-if="surveyModel.responseType === 2"
                :style="{
                  width: surveyModel.responseType === 2 && this.step === 1 ? '100%' : '50%',
                }"
              >
                <BrandButton class="next-button" @click.prevent="nextButton">
                  <VueText color="white-100" sizeLevel="10" weightLevel="3">DEVAM</VueText>
                </BrandButton>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </BrandSurveyPopup>
    <!-- ticket -->
    <BrandSurveyPopup :show.sync="showTicketSurvey">
      <div class="static-content-modal">
        <div class="ticket-survey-wrapper" v-if="!isSubmitted">
          <h2 class="ticket-survey-question">
            {{ surveyQuestion }}
          </h2>
          <VueListView dir="row" class="option-list">
            <VueButton
              :class="[isSolved === true && 'active', 'option']"
              :isSingleLine="false"
              outlined
              @click="onAnswerSelect(true)"
              >Evet</VueButton
            >
            <VueButton
              :class="[isSolved === false && 'active', 'option']"
              :isSingleLine="false"
              outlined
              @click="onAnswerSelect(false)"
              >Hayır</VueButton
            >
          </VueListView>
          <div class="ticket-subquestion" v-if="isSolved">
            <h2 class="point-question">Aldığınız destekten memnun kaldınız mı?</h2>
            <div class="point-list">
              <span
                v-for="(i, index) in 5"
                :key="index"
                :class="[selectedPoint === i && 'active', 'point-item']"
                @click="onPointSelect($event)"
                >{{ i }}</span
              >
            </div>
            <div class="vote-info">
              <img src="@/assets/icons/info.svg" alt="pmaktif" class="vote-info-icon" />
              <div class="vote-info-text">
                <p>
                  <span>1-5</span>
                  arasında bir puan verin
                </p>
                <p><span>5</span> Çok memnun kaldım. <span>1</span> Hiç memnun kalmadım.</p>
              </div>
            </div>
            <div class="ticket-open-text-wrapper" v-if="selectedPoint !== 0">
              <label for="ticket-open-text-1"
                >Destek talebinize ilişkin eklemek istediğiniz yorumunuz var mı?</label
              >
              <textarea
                id="ticket-open-text-1"
                placeholder="Mesajınızı yazınız..."
                class="ticket-open-text"
                rows="3"
                v-model="solvedMessage"
              ></textarea>
            </div>
          </div>
          <div class="ticket-open-text-wrapper" v-if="isSolved === false">
            <label for="ticket-open-text-2"
              >Destek talebinize ilişkin eklemek istediğiniz yorumunuz var mı?</label
            >
            <textarea
              id="ticket-open-text-2"
              placeholder="Mesajınızı yazınız..."
              class="ticket-open-text"
              rows="3"
              v-model="notSolvedMessage"
            ></textarea>
          </div>
          <BrandButton
            class="ticket-survey-btn"
            :disabled="
              isSolved === null ||
                (isSolved === true && selectedPoint === 0) ||
                (isSolved === false && notSolvedMessage === '')
            "
            @click.prevent="onTicketSurveySubmit()"
          >
            <VueText color="white-100" sizeLevel="10" weightLevel="3">GÖNDER</VueText>
          </BrandButton>
        </div>
        <div class="ticket-survey-wrapper result" v-if="isSubmitted">
          <div class="result-wrapper">
            <div class="result-content">
              <VueIcon
                class="result-content-icon"
                iconName="IconApply"
                :width="40"
                :height="40"
                color="#E5472D"
              />
              {{
                isSolved
                  ? 'Geri bildiriminiz için teşekkür ederiz.'
                  : 'Geri bildiriminiz için teşekkür ederiz. En kısa sürede talebinize dair size geri dönüş yapılacaktır.'
              }}
            </div>
            <BrandButton
              class="ticket-survey-btn"
              @click.prevent="() => (showTicketSurvey = !showTicketSurvey)"
            >
              <VueText color="white-100" sizeLevel="10" weightLevel="3">TAMAM</VueText>
            </BrandButton>
          </div>
        </div>
      </div>
    </BrandSurveyPopup>
  </div>
</template>

<script>
import BrandSurveyPopup from '@/components/brand/Modals/BrandModal/BrandSurveyPopup.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueListView from '@/components/shared/VueListView/VueListView.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { Gamification } from '@/services/Api/index';
import { mapGetters } from 'vuex';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Datepicker from 'vuejs-datepicker';
import { tr } from 'vuejs-datepicker/dist/locale';
import gtmUtils from '@/mixins/gtmUtils.js';
import RosetteInfo from '@/mixins/rosetteInfo.js';

export default {
  name: 'NpsSurvey',
  mixins: [RosetteInfo, gtmUtils],
  components: {
    BrandSurveyPopup,
    VueText,
    BrandButton,
    BrandMediaContentBox,
    BrandVimeoPlayer,
    VueButton,
    VueListView,
    VueIcon,
    VueTimepicker,
    Datepicker,
  },
  props: {
    npsSurveyId: {
      type: Number,
      default: null,
    },
    currentPopup: {},
    isRosetteNps: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      point: null,
      openText: null,
      step: 0,
      selectedAnswers: [],
      staticModal: { show: false, data: '' },
      showTicketSurvey: false,
      showPopupSurvey: false,
      ticketId: 0,
      isSolved: null,
      selectedPoint: 0,
      solvedMessage: '',
      notSolvedMessage: '',
      isSubmitted: false,
      dailyModel: null,
      surveyDetail: null,
      surveyDetailModel: null,
      surveyModel: {
        questionCount: null,
      },
      questionCount: null,
      priorty: null,
      surveyId: null,
      surveyTakenId: null,
      otherOption: false,
      evulationSurveyType: null,
      surveyQuestion: '',
      surveyDescription: null,
      tr: tr,
      date: '',
      hour: '',
    };
  },
  watch: {
    otherOption: {
      handler(newVal) {
        if (newVal === false) {
          this.openText = null;
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['getUserDetails', 'getAfterLogin']),
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        mediaRatio: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS.by_16_9,
        icons: ICON_VARIABLES,
      };
    },
    filteredVote() {
      let minVote = this.surveyModel.mainQuestion.subQuestions[0].minPoint;
      let maxVote = this.surveyModel.mainQuestion.subQuestions[2].maxPoint;
      let minOrange = this.surveyModel.mainQuestion.subQuestions[1].minPoint;
      let minGreen = this.surveyModel.mainQuestion.subQuestions[2].minPoint;

      let array = [];
      for (let i = minVote; i <= maxVote; i++) {
        let voteClass;
        if (i < minOrange) {
          voteClass = 'red';
        } else if (i < minGreen) {
          voteClass = 'orange';
        } else {
          voteClass = 'green';
        }
        array.push({
          value: i,
          voteClass: voteClass,
        });
      }
      return array;
    },
    media() {
      if (!this.surveyModel.mainQuestion) {
        return;
      }

      let type = '';
      if (
        this.surveyModel.mainQuestion.questionContentType == 3 &&
        this.surveyModel.mainQuestion.embedVideo
      ) {
        type = MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.embed;
      } else if (
        this.surveyModel.mainQuestion.questionContentType == 2 &&
        this.surveyModel.mainQuestion.image
      ) {
        type = MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.image;
      }

      let enabled = type != '' ? true : false;
      let content = '';
      if (type == 'embed') {
        content = this.surveyModel.mainQuestion.embedVideo;
      } else if (type == 'image') {
        content = this.surveyModel.mainQuestion.image;
      }

      return { type, enabled, content, ratio: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS.by_16_9 };
    },
    setNextButtonDisable() {
      return (
        !!this.surveyModel.question.isRequired &&
        (!(this.selectedAnswers.length > 0 || this.openText) ||
          (this.surveyModel.question.openText && !this.openText))
      );
    },
  },
  created() {
    this.getSurveyPopup();
  },
  methods: {
    closeModal() {
      this.setEventData(false);
      this.$emit('closeModal', true);
      this.$store.dispatch('app/setPopups', {});
      document.body.style.overflow = 'unset';
    },
    setEventData(isClick) {
      const eventData = {
        event: 'cEvent',
        category: 'Pop Up',
        action: isClick ? 'Click' : 'Close',
        label: 'NPS',
        value: 0,
        isNonInteraction: false,
      };
      this.pushDataLayerEvent('pop-up', eventData);
    },
    onTicketSurveySubmit() {
      let model = {
        ticketId: this.ticketId,
        isSolved: this.isSolved,
        customerComment: this.isSolved ? this.solvedMessage : this.notSolvedMessage,
        point: this.selectedPoint,
      };
      Gamification.answerTicketSurvey(model).then(response => {
        if (response.data && response.data.Data) {
          this.isSubmitted = true;
        }
      });
    },
    onPointSelect(e) {
      var elems = document.querySelectorAll('.point-item');
      [].forEach.call(elems, function(el) {
        el.classList.remove('active');
      });
      e.target.classList.add('active');
      this.selectedPoint = Number(e.target.innerText);
    },
    onAnswerSelect(val) {
      this.isSolved = val;
      if (val === false) {
        this.selectedPoint = 0;
        this.solvedMessage = '';
      }
    },
    getSurveyPopup() {
      this.isSubmitted = false;
      this.isSolved = null;
      this.solvedMessage = '';
      this.notSolvedMessage = '';
      this.selectedPoint = 0;

      if (this.currentPopup?.popupDetail?.ticketId > 0) {
        this.ticketId = this.currentPopup?.popupDetail?.ticketId;
        if (this.ticketId) {
          this.ticketSurvey = true;
          this.getTicketSurveyDetail();
        }
      } else {
        this.showTicketSurvey = false;
        let afterLogin = this.getAfterLogin;
        this.dailyModel = {
          afterLogin: afterLogin,
        };
        this.getNpsSurvey();
      }
    },
    getNpsSurvey() {
      this.surveyDetail = {
        surveyId: this.npsSurveyId ? this.npsSurveyId : this.currentPopup?.popupDetail?.surveyId,
        isRosetteNps: this.isRosetteNps,
      };

      if (this.npsSurveyId || this.currentPopup?.popupDetail?.surveyId) {
        this.getSurveyDetail();
      }

      if (this.getAfterLogin) {
        this.$store.dispatch('auth/setAfterLogin', false);
        this.dailyModel.afterLogin = this.getAfterLogin;
      }
    },
    getSurveyDetail() {
      Gamification.getNpsSurveyDetail({ ...this.surveyDetail }).then(res => {
        const {
          Data: { surveyModel },
        } = res.data;
        this.evulationSurveyType = surveyModel.evulationSurveyType;
        this.surveyModel = surveyModel;
        this.questionCount = this.surveyModel.questionCount;
        this.surveyDescription = this.surveyModel.surveyDescription;
        this.staticModal.show =
          this.surveyModel.evulationSurveyType === 6 || this.surveyModel.evulationSurveyType === 7;
        this.showPopupSurvey = this.surveyModel.evulationSurveyType === 8;
      });
    },
    getTicketSurveyDetail() {
      Gamification.getTicketSurveyDetail(this.ticketId).then(res => {
        const {
          Data: { surveyQuestion },
        } = res.data;
        this.showTicketSurvey = true;
        this.surveyQuestion = surveyQuestion;
      });
    },
    setAnswers(isPrev = false) {
      let requestModel = null;
      this.otherOption = false;
      if (this.surveyModel.responseType === 1) {
        requestModel = { ...this.surveyModel };
      } else if (
        this.surveyModel.responseType === 2 &&
        this.surveyModel.evulationSurveyType !== 8
      ) {
        this.priorty = this.surveyModel.mainQuestion.priorty;
        this.surveyId = this.surveyModel.surveyId;
        this.surveyTakenId = this.surveyModel.surveyTakenId;
        requestModel = {
          SurveyId: this.surveyId,
          isEOrderUser: this.getUserDetails.is_eorder_user,
          responseType: this.surveyModel.responseType,
          surveyTakenId: this.surveyTakenId,
          questionId: this.surveyModel.mainQuestion.questionId,
          point: this.point === 'noInterview' ? null : this.point,
          priorty: this.priorty,
        };
      } else if (
        this.surveyModel.responseType === 3 ||
        this.surveyModel.evulationSurveyType === 8
      ) {
        if (this.surveyModel.evulationSurveyType === 8) {
          this.priorty = this.surveyModel.mainQuestion.priorty;
          this.surveyId = this.surveyModel.surveyId;
          this.surveyTakenId = this.surveyModel.surveyTakenId;
        }
        requestModel = {
          SurveyId: this.surveyId,
          isEOrderUser: this.getUserDetails.is_eorder_user,
          responseType: this.surveyModel.responseType,
          surveyTakenId: this.surveyTakenId,
          questionId:
            this.surveyModel.evulationSurveyType !== 8
              ? this.surveyModel.question.questionId
              : this.surveyModel.mainQuestion.questionId,
          openText: this.openText,
          options: this.selectedAnswers,
          date: this.dateFormatter(this.date, '/'),
          hour: this.hour,
          priorty: this.priorty,
          mainQuestionId:
            this.surveyModel.evulationSurveyType !== 8
              ? this.surveyModel.question.mainQuestionId
              : this.surveyModel.mainQuestion.questionId,
        };
      }
      if (!isPrev) {
        Gamification.setNpsAnswers({ ...requestModel }).then(res => {
          const {
            Data: { surveyModel },
          } = res.data;
          this.surveyModel = surveyModel;
          this.selectedAnswers = [];
          if (this.surveyModel.responseType === 2) {
            this.step = this.surveyModel.mainQuestion.priorty;
            this.point = this.surveyModel.mainQuestion.answer?.point;
            this.openText = this.surveyModel.mainQuestion.answer?.opentext;
            this.otherOption = this.openText ? true : false;
          } else if (this.surveyModel.responseType !== 4) {
            this.openText = this.surveyModel.question.answer
              ? this.surveyModel.question.answer.opentext
              : null;
            this.selectedAnswers = this.surveyModel.question.answer
              ? this.surveyModel.question.answer.optionid
              : [];
            if (!this.surveyModel.question.answer) {
              let selectedAll = document.querySelectorAll('.vote-item');
              selectedAll.forEach(item => {
                item.classList.remove('active');
              });
            }
          }
        });
      } else {
        Gamification.setNpsPreviousAnswer({ ...requestModel }).then(res => {
          const {
            Data: { surveyModel },
          } = res.data;
          this.surveyModel = surveyModel;
          if (this.surveyModel.responseType === 2) {
            if (this.evulationSurveyType === 8) {
              this.openText = this.surveyModel.mainQuestion.answer.opentext;
              this.otherOption = this.surveyModel.mainQuestion.answer.opentext ? true : false;
              this.selectedAnswers = this.surveyModel.mainQuestion.answer.optionid;
              this.step = this.step - 1;
              this.priorty = this.priorty - 1;
            } else {
              this.point = this.surveyModel.mainQuestion.answer.point;
              this.priorty = this.surveyModel.mainQuestion.priorty;
              this.step = this.priorty;
            }
          } else {
            this.step = this.step - 1;
            this.priorty = this.priorty - 1;
            this.openText = this.surveyModel.question.answer.opentext;
            this.selectedAnswers = this.surveyModel.question.answer.optionid;
          }
        });
      }
    },
    startSurvey() {
      this.setEventData(true);
      this.setAnswers();
    },
    nextButton() {
      if (this.surveyModel.responseType === 3) {
        if (this.surveyModel.question.isRequired && this.surveyModel.question.openText) {
          this.setAnswers();
        }
      }
      this.setAnswers();
      if (this.surveyModel.responseType === 4) {
        this.staticModal.show = false;
      }
    },
    prevButton() {
      this.setAnswers(true);
    },
    pointVote(vote) {
      this.point = vote;
      let selectedAll = document.querySelectorAll('.vote-item');
      selectedAll.forEach(item => {
        item.classList.remove('active');
      });

      if (this.point || this.point === 0) {
        let selected = document.querySelector(`[data-id="${vote}"]`);
        selected.classList.add('active');
      }

      let selectedInterview = document.querySelector('.no-interview');
      if (selectedInterview) {
        selectedInterview.classList.remove('active');
      }
    },
    pointInterview() {
      let selectedAll = document.querySelectorAll('.vote-item');
      selectedAll.forEach(item => {
        item.classList.remove('active');
      });
      let selectedInterview = document.querySelector('.no-interview');
      selectedInterview.classList.add('active');
      this.point = 'noInterview';
    },
    isVoteActive(i) {
      return this.point === i;
    },
    selectOption(optionId) {
      if (this.selectedAnswers.includes(optionId)) {
        this.selectedAnswers.splice(this.selectedAnswers.indexOf(optionId), 1);
      } else {
        this.selectedAnswers = [];
        this.selectedAnswers.push(optionId);
      }
    },
    dateFormatter(date, separator = '-', reverse = false) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      if (reverse) {
        return [year, month, day].join(separator);
      } else {
        return [day, month, year].join(separator);
      }
    },
    onFinishSurvey() {
      this.showPopupSurvey = false;
      this.staticModal.show = false;
      this.$emit('closeModal', false);
      this.triggerRefreshRosettes();
    },
    triggerRefreshRosettes() {
      this.getRosettesList();
      this.getRosetteRewardStatu();
    },
  },
};
</script>

<style scoped lang="scss">
.static-content-modal {
  max-width: 650px;
  min-width: 450px;
  .modal-content {
    max-height: 100%;
    overflow: initial;
  }
}
.nps-start-screen {
  text-align: center;
  img {
    display: block;
    width: 100%;
    max-width: 202px;
    height: auto;
    margin: 60px auto 30px;
  }
  .modal-content {
    padding: 20px 40px 0;
    .content-text {
      p {
        display: inline-block;
        max-width: 619px;
        font-size: 22px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 30px;
        span.bold-text {
          font-weight: 500;
        }
      }
    }
    /deep/ .info-text {
      max-width: 619px;
      font-size: 22px;
      font-weight: 400;
      line-height: 32px;
      p {
        font-family: 'Roboto', sans-serif !important;
      }
    }
  }

  .start-button {
    margin-top: 24px;
    padding: 30px 30px;
  }
}
.question-screen {
  position: relative;
  margin: 0 auto;
  width: 650px;
  .step-bar {
    width: 80%;
    position: relative;
    z-index: 1;
    left: 50%;
    margin-left: -170px;
    margin-top: 30px;
    margin-bottom: 20px;
    display: inline-block;
    ul.progressbar {
      list-style-type: none;
      counter-reset: step;
      li {
        float: left;
        width: calc(100% / 4);
        position: relative;
        text-align: center;
        &:before {
          content: counter(step);
          counter-increment: step;
          width: 38px;
          height: 38px;
          line-height: 30px;
          border: solid 4px #ebebeb;
          display: block;
          margin: 0 auto 10px auto;
          border-radius: 50%;
          background: white;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          color: #ebebeb;
        }
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 4px;
          background: #ebebeb;
          top: 15px;
          left: -50%;
          z-index: -1;
        }
        &.active:after {
          background-image: linear-gradient(105deg, #ffa300 0%, #d3044f 99%);
        }
        &.active:before {
          background: #d3044f;
          border: solid 4px #d3044f;
          line-height: 30px;
          color: white;
        }
      }
    }
  }
  .modal-content {
    padding: 20px 40px 0;
    .content-text {
      text-align: center;
      .question-text {
        text-align: center;
        margin-bottom: 30px;
        p {
          display: inline-block;
          max-width: 570px;
          font-size: 20px;
          line-height: 30px;
        }
      }
      ul.questions-answers {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: left;
        li {
          display: block;
          width: 100%;
          margin-bottom: 14px;
          .container {
            display: inline-block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 5px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            font-size: 18px;
            font-weight: 400;
            color: #23303d;
          }

          /* Hide the browser's default checkbox */
          .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: transparent;
            border: solid 1px #79838e;
            border-radius: 4px;
          }

          /* On mouse-over, add a grey background color */
          .container:hover input ~ .checkmark {
            background-color: transparent;
          }

          /* When the checkbox is checked, add a blue background */
          .container input:checked ~ .checkmark {
            border-radius: 4px;
            background-color: #23303d;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .container .checkmark:after {
            left: 7px;
            top: 4px;
            width: 5px;
            height: 9px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .question-text-area {
        textarea {
          &.option {
            border: 0.5px solid palette-color('grey-30');
            border-radius: 4px;
            width: 100%;
            padding: 20px;
            margin-top: 10px;
            color: #23303d;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
          }
        }
      }
      ul.question-vote {
        text-align: center;
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          float: left;
          a {
            display: inline-block;
            width: 39px;
            height: 39px;
            line-height: 39px;
            border-radius: 50px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            &.red {
              border: solid 2px #e02020;
              color: #e02020;
            }
            &.orange {
              border: solid 2px #fa6400;
              color: #fa6400;
            }
            &.green {
              border: solid 2px #6dd400;
              color: #6dd400;
            }
          }
        }
        li.active {
          float: left;
          a {
            display: inline-block;
            width: 39px;
            height: 39px;
            line-height: 39px;
            border-radius: 50px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            &.red {
              background: #e02020;
              border: solid 2px #e02020;
              color: white;
            }
            &.orange {
              background: #fa6400;
              border: solid 2px #fa6400;
              color: white;
            }
            &.green {
              background: #6dd400;
              border: solid 2px #6dd400;
              color: white;
            }
          }
          .no-interview-item {
            border: solid 1px #23303d;
            background: #23303d;
            color: white;
            margin-left: 0;
          }
        }
        .no-interview {
          a {
            color: #79838e;
            border: 2px solid #79838e;
          }
          span {
            margin-top: 10px;
            display: block;
            color: #79838e;
            max-width: 100px;
            width: 100%;
          }
        }
      }
      .vote-info {
        margin-top: 20px;
        text-align: left;
        .vote-info-icon {
          display: inline-block;
        }
        .vote-info-text {
          position: relative;
          top: 10px;
          left: 10px;
          display: inline-block;
          span {
            font-weight: 600;
          }
        }
      }
      .finally-screen {
        width: 100%;
        text-align: center;
        padding-top: 40px;
        p {
          display: inline-block;
          width: 100%;
          margin: 30px 0 20px 0;
          font-size: 32px;
          font-weight: 600;
          text-align: center;
          color: #000000;
        }
        span {
          display: inline-block;
          text-align: center;
          margin: 20px 0 0;
          font-size: 28px;
          font-weight: 400;
          text-align: center;
          color: #000000;
        }
        img {
          display: inline-block;
        }
      }
    }
  }
  .question-button-wrapper {
    .finally-button {
      margin-top: 30px;
      padding: 30px 30px;
    }
    ul.question-buttons {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        display: inline-block;
        width: 50%;
        /* width: 100%; */
        .prev-button {
          margin-top: 30px;
          padding: 30px 30px;
          padding: 28px;
          border-radius: 0;
        }
        .next-button {
          margin-top: 30px;
          padding: 30px 30px;
          border-radius: 0;
        }
      }
    }
  }
}
.media-container {
  padding: 30px 0;
}
.ticket-survey-wrapper {
  width: 650px;
  min-height: 300px;
  padding: 60px 40px 90px;
  position: relative;
  font-size: 20px;
  text-align: center;
  &.result {
    text-align: center;
    font-size: 21px;
  }
}
.ticket-survey-question,
.point-question {
  line-height: 1.5;
}
.point-question {
  margin-bottom: 10px;
}
.ticket-survey-wrapper {
  .option-list {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    .option {
      border: 0.5px solid palette-color('grey-30');
      border-radius: 4px;
      margin-bottom: palette-space-level(10);
      font-size: palette-font-size-level(6);
      font-weight: palette-font-weight-level(3);
      width: 110px;
      display: flex;
      justify-content: center;
      &.active {
        background-color: palette-color-level('grey', 30) !important;
        color: palette-color-level('white', 100) !important;
      }
    }
    li:last-child .option {
      margin-left: 20px;
    }
  }
}
.ticket-subquestion {
  margin-top: 10px;
  .point-title {
    margin-bottom: 20px;
  }
  span.point-item {
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 41px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid #23303d;
    color: #23303d;
    &:first-child,
    &:nth-child(2) {
      border-color: #e02020;
      color: #e02020;
      &.active {
        background-color: #e02020;
        color: #fff;
      }
    }
    &:nth-child(3) {
      border-color: #fa6400;
      color: #fa6400;
      &.active {
        background-color: #fa6400;
        color: #fff;
      }
    }
    &:nth-child(4),
    &:nth-child(5) {
      border-color: #6dd400 !important;
      color: #6dd400;
      &.active {
        background-color: #6dd400;
        border-color: #6dd400;
        color: #fff;
      }
    }
  }
  .vote-info {
    margin-top: 10px;
    text-align: left;
    font-size: 16px;
    .vote-info-icon {
      display: inline-block;
    }
    .vote-info-text {
      position: relative;
      top: 10px;
      left: 10px;
      display: inline-block;
      span {
        font-weight: 600;
      }
    }
  }
  .ticket-open-text-wrapper {
    margin-top: 30px;
  }
}
.ticket-survey-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
}
.ticket-open-text-wrapper {
  margin-top: 20px;
  display: block;
}
.ticket-open-text {
  margin-top: 10px;
  display: block;
  border: 0.5px solid palette-color('grey-30');
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  color: #23303d;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  &:focus {
    outline: 0.5px solid palette-color('grey-30');
  }
}
.result-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  &-icon {
    margin-bottom: 10px;
  }
}
.option-list {
  .option {
    border: 0.5px solid palette-color('grey-30');
    margin-bottom: palette-space-level(10);
    font-size: palette-font-size-level(6);
    font-weight: palette-font-weight-level(3);
  }
}
.selected-option {
  background-color: palette-color-level('grey', 30) !important;
  color: palette-color-level('white', 100) !important;
}
.other-option {
  text-align: left;
  margin-top: 5px;
  .container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 18px;
    font-weight: 400;
    color: #23303d;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: solid 1px #79838e;
    border-radius: 4px;
  }
  .container:hover input ~ .checkmark {
    background-color: transparent;
  }
  .container input:checked ~ .checkmark {
    border-radius: 4px;
    background-color: #23303d;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    left: 6px;
    top: 5px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.option-list {
  margin-top: 20px;
  .option {
    width: 100%;
    &:last-child {
      margin-left: 0 !important;
    }
  }
}

.picker-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.time-picker {
  text-align: right;
  position: relative;
  &::before {
    content: 'Saat';
    position: absolute;
    top: 10px;
    left: 24px;
    color: #303030;
    font-weight: 300;
    z-index: 3;
  }
}
.vue__time-picker {
  /deep/ input {
    border: none;
    height: auto;
    font-size: 22px;
    width: 160px;
    height: 80px;
    padding: 24px 20px 0;
    border: 1px solid #23303d;
    border-radius: 4px;
    color: #23303d;
    &::placeholder {
      color: #303030 !important;
    }
  }
}

div.dropdown.drop-down > div > ul.minutes > li.active {
  background: '#e47b49';
}
/deep/ .time-picker-dropdown ul li,
.time-picker .dropdown ul li.active:hover {
  background: '#e47b49' !important;
}
</style>
<style lang="scss">
.vue__time-picker-dropdown,
.vue__time-picker .dropdown {
  top: 70px;
}
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: linear-gradient(315deg, #d20051, #ffa300);
}
.vdp-datepicker__calendar .cell.selected {
  background: linear-gradient(315deg, #d20051, #ffa300) !important;
  color: #fff;
}
</style>
